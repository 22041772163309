import React, {FC, useEffect, useReducer, useState} from 'react';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {VerdocsTemplateCreate} from '@verdocs/web-sdk-react';
import {invalidateQuery} from '../../react-sdk/queries';
import {StandardView} from '../../Components';
import {URLS} from '../../Routing';

// const MyComponent = ({label}: {label: string}) => {
//   const [localLabel, setLocalLabel] = useState(label);
//   return <div onClick={() => setLocalLabel('Clicked')}>{localLabel}</div>;
// };

// const MyWrapper = ({label}: {label: string}) => {
//   return <MyComponent label={label} />;
// };

export const NewTemplate: FC = () => {
  const navigate = useNavigate();

  // Cheap trick to trigger a re-render
  // Strict mode will detect the problem without this
  const [, rerender] = useReducer(() => ({}), {});
  useEffect(() => {
    rerender();
  }, []);

  const handleTemplateCreated = (e: any) => {
    console.log('Created template', e.detail);
    invalidateQuery(['templates']);
    navigate(URLS.TEMPLATE_DETAIL.build(e.detail.id, 'roles'));
    // navigate(URLS.TEMPLATE_ROLES.build(e.detail.id));
  };

  const randomLengthArray = Array.from({length: Math.floor(Math.random() * 10)}, (_, i) => i);

  return (
    <StandardView style={{padding: 0}}>
      {/*{randomLengthArray.map((i) => (<MyComponent label={String(i)} />))}*/}
      {/*{randomLengthArray.map((i) => React.createElement(MyComponent, {label: String(i)}))}*/}

      {/*<MyComponent label="Test" />*/}
      {/*{MyComponent({label: 'Test'})}*/}

      <div className="flex flex-row items-center justify-center pt-20">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl desktop:text-3xl mb-8">Create Template</h1>
          {/*<Wizard currentStep="attachments" completedSteps={[]} />*/}
          <VerdocsTemplateCreate
            onNext={handleTemplateCreated}
            onExit={() => navigate(URLS.DASHBOARD.build())}
            onSdkError={(e: any) => {
              console.log('Error creating template', e);
              toast.error('Error creating template, please try again: ' + e.detail.message);
            }}
          />
        </div>
      </div>
    </StandardView>
  );
};
