import {useQuery} from '@tanstack/react-query';
import {getEnvelopes, getSignatures, ITimeRange, TEnvelopeStatus, VerdocsEndpoint} from '@verdocs/js-sdk';
import {setSession} from '../../AppState';

export interface IListEnvelopesParams {
  view?: 'inbox' | 'sent' | 'action' | 'waiting' | 'completed';
  q?: string;
  status?: TEnvelopeStatus[];
  created_at?: ITimeRange;
  is_owner?: boolean;
  sort_by?: 'name' | 'created_at' | 'updated_at' | 'canceled_at' | 'status';
  template_id?: string;
  ascending?: boolean;
  rows?: number;
  page?: number;
}

export const useEnvelopes = (params: IListEnvelopesParams) =>
  useQuery({
    queryKey: ['envelopes', 'list', params],
    retry: 3,
    refetchInterval: 30000,
    queryFn: () =>
      getEnvelopes(VerdocsEndpoint.getDefault(), params).catch((e) => {
        // NOTE: We used to do this globally but if ANY endpoint had an issue, no matter how
        // minor (or dev-related bug), we would get booted. Now we just do it in a few
        // selective spots to have more control over what happens.
        if (e.response?.status === 401) {
          console.log('Session expired');
          setSession(null, null);
        }

        throw e;
      }),
    placeholderData: (previousData) => previousData,
  });

export const useSignatures = () => useQuery({queryKey: ['signatures'], queryFn: () => getSignatures(VerdocsEndpoint.getDefault())});

export const useTemplateEnvelopes = (id: string) =>
  useQuery({queryKey: [], queryFn: () => getEnvelopes(VerdocsEndpoint.getDefault(), {template_id: id})});
