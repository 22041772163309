export const URLS = {
  DASHBOARD: {route: '/', build: () => '/'},
  OLD_DASHBOARD: {route: '/dashboard', build: () => '/dashboard'},
  CREATE_ORG: {route: '/create-org', build: () => '/create-org'},
  LOGIN: {route: '/login', build: () => '/login'},
  SIGNUP: {route: '/signup', build: () => '/signup'},
  // TODO
  VERIFY_EMAIL: {route: '/verify-email', build: () => '/verify-email'},
  // TODO
  RESET_PASSWORD: {route: '/reset-password', build: () => '/reset-password'},
  AUTHORIZE_OAUTH2: {route: '/oauth2/authorize', build: () => '/oauth2/authorize'},
  ACCEPT_INVITE: {route: '/join/:id/:email/:token'},

  ENVELOPES: {route: '/envelopes', build: (viewAllParams?: string) => `/envelopes${viewAllParams || ''}`},
  ENVELOPE_FINISH: {route: '/envelope/finish/:id/:role', build: (id: string, role: string) => `/envelope/finish/${id}/${role}`},
  ENVELOPE_DETAIL: {route: '/envelope/:id', build: (id: string) => `/envelope/${id}`},

  TEMPLATES: {route: '/templates', build: () => '/templates'},
  TEMPLATE_DETAIL: {route: '/template/:id', build: (id: string, tab: string = 'preview') => `/template/${id}#${tab}`},
  SUBMITTED_DATA: {route: '/template/:id/submitted', build: (id: string) => `/template/${id}/submitted`},
  NEW_TEMPLATE: {route: '/templates/add', build: () => `/templates/add`},

  // React Router supports optional path params but we need the auth-required block to have
  // something to hook into as well and this was easier.
  SIGN_ANONYMOUSLY: {route: '/sign/:id/:role/:code', build: (id: string, role: string, code: string) => `/sign/${id}/${role}/${code}`},
  SIGN_ANONYMOUSLY_OLD: {
    route: '/view/sign/:id/roleName/:role/invitation/:code',
    build: (id: string, role: string, code: string) => `/view/sign/${id}/roleName/${role}/invitation/${code}`,
  },
  SIGN_IN_PERSON: {route: '/sign/:id/:role' + '', build: (id: string, role: string) => `/sign/${id}/${role}`},

  SETTINGS_BASE: {route: '/settings', build: () => `/settings`},
  SETTINGS_PROFILE: {route: '/settings/profile', build: () => `/settings/profile`},
  SETTINGS_SECURITY: {route: '/settings/security', build: () => `/settings/security`},
  SETTINGS_SIGNATURE: {route: '/settings/signature', build: () => `/settings/signature`},
  SETTINGS_NOTIFICATIONS: {route: '/settings/notifications', build: () => `/settings/notifications`},
  SETTINGS_ORGANIZATION: {route: '/settings/organization', build: () => `/settings/organization`},
  SETTINGS_BILLING: {route: '/settings/billing', build: () => `/settings/billing`},
  SETTINGS_MEMBERS: {route: '/settings/members', build: () => `/settings/members`},
  SETTINGS_GROUPS: {route: '/settings/groups', build: () => `/settings/groups`},
  SETTINGS_USAGE: {route: '/settings/usage', build: () => `/settings/usage`},
  SETTINGS_DISCLAIMER: {route: '/settings/disclaimer', build: () => `/settings/disclaimer`},
  SETTINGS_API_KEYS: {route: '/settings/api-keys', build: () => `/settings/api-keys`},
  SETTINGS_WEBHOOKS: {route: '/settings/webhooks', build: () => `/settings/webhooks`},
  SETTINGS_DELETEORG: {route: '/settings/delete-org', build: () => `/settings/delete-org`},

  EMBEDS_AUTH: {route: '/embeds/auth', build: () => `/embeds/auth`},
  EMBEDS_BUILD: {route: '/embeds/build/:id', build: (id: string) => `/embeds/build/${id}`},
  EMBEDS_VIEW: {route: '/embeds/view/:id', build: (id: string) => `/embeds/view/${id}`},
  EMBEDS_PREVIEW: {route: '/embeds/preview/:id', build: (id: string) => `/embeds/preview/${id}`},
  EMBEDS_SEND: {route: '/embeds/send/:id', build: (id: string) => `/embeds/send/${id}`},
  EMBEDS_SIGN: {
    route: '/embeds/sign/:id/:role/:code',
    build: (id: string, role: string, code: string) => `/embeds/sign/${id}/${role}/${code}`,
  },
};
