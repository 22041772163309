const BETA_ORIGINS = ['https://beta.verdocs.com', 'https://stage.verdocs.com', 'http://localhost:5173'];
const IS_BETA = BETA_ORIGINS.includes(window.location.origin);

export const CONFIG = {
  IS_BETA,
  API_URL: IS_BETA ? 'https://stage-api.verdocs.com' : 'https://api.verdocs.com',
  PERSONAL_ORGID: '65b59646-64fe-48df-850f-05887ddf003b',
  UNLEASH_API_KEY: IS_BETA
    ? 'default:development.6348f953f52485d19daf03992f2123a36e9eae59c5ca7f8736dc2b67'
    : 'default:production.4808a77b2a149e1796391b480b4fb501d73aa1e11123f60e07809ced',
  UNLEASH_ENVIROMENT: IS_BETA ? 'development' : 'production',
};
