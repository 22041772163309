import axios from 'axios';
import {useQuery} from '@tanstack/react-query';
import {CONFIG} from '../config';

const {UNLEASH_API_KEY, UNLEASH_ENVIROMENT} = CONFIG;

// NOTE: Unleash provides an SDK but its error handling was a bit too simplistic (just console.error
// for almost everything, with no reporting back to the higher level components) for our needs. Since
// the API for Unleash is so simple, we just wrap it ourselves here and call it directly.

interface IFeatureFlags {
  toggles: {
    name: string;
    enabled: boolean;
    impressionData: boolean;
    variant: {
      name: string;
      enabled: boolean;
      feature_enabled: boolean;
      featureEnabled: boolean;
    };
  }[];
}

const getFeatureFlags = () =>
  axios
    .get<IFeatureFlags>('https://unleash.verdocs.com/api/frontend', {
      headers: {
        Accept: 'application/json',
        Authorization: UNLEASH_API_KEY,
        'unleash-appname': 'web',
        'unleash-sdk': 'unleash-client-js:3.7.3',
        environment: UNLEASH_ENVIROMENT,
      },
    })
    .then((r) => r.data)
    .catch((e) => console.log('Unleash: Unable to fetch feature flags', e));

const useFeatureFlags = () =>
  useQuery({
    queryKey: ['FEATURE_FLAGS'],
    queryFn: getFeatureFlags,
    refetchInterval: 60000,
  });

export const useFeatureFlag = (flag: string) => {
  const flagsQuery = useFeatureFlags();

  return flagsQuery.data?.toggles.find((f) => f.name === flag)?.enabled || false;
};
