import {z} from 'zod';
import React, {FC, useEffect, useRef, useState} from 'react';
// import {createEditor} from 'slate';
import JoditEditor from 'jodit-react';

import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';
import {zodResolver} from '@hookform/resolvers/zod';
import {VerdocsButton} from '@verdocs/web-sdk-react';
import {FormProvider, useForm} from 'react-hook-form';
// import {Slate, Editable, withReact} from 'slate-react';
import {getOrganization, updateOrganization, VerdocsEndpoint} from '@verdocs/js-sdk';
import {convertToE164, IOrganization, updateOrganizationLogo, updateOrganizationThumbnail} from '@verdocs/js-sdk';
import {FormColumn, FormColumns, PhotoSelector, ColorPicker, TextInput, HelpDialog, FormLabel, FlexFill} from '../../Components';
import {invalidateQuery} from '../../react-sdk/queries';
import {AppState} from '../../AppState';
import {Jodit} from 'jodit';

const schema = z.object({
  disclaimer: z.string().trim(),
});

type SchemaType = z.infer<typeof schema>;

const getEditableFields = (organization?: IOrganization) => ({
  disclaimer: `                
<li>
  Agree to use electronic records and signatures, and confirm you have read the
  <a href="https://verdocs.com/en/electronic-record-signature-disclosure/" target="_blank">
    Electronic Record and Signatures Disclosure
  </a>
  .
</li>
<li>
  Agree to Verdocs'
  <a href="https://verdocs.com/en/eula" target="_blank">
    End User License Agreement
  </a>
  and confirm you have read Verdocs'
  <a href="https://verdocs.com/en/privacy-policy/" target="_blank">
    Privacy Policy
  </a>.
</li>
`,
});

export const Disclaimer: FC = observer(() => {
  const organizationId = AppState.currentProfile?.organization_id || '';
  const editor = useRef(null);

  // const [editor] = useState(() => withReact(createEditor()));

  const [newLogo, setNewLogo] = useState<File | null>(null);
  const [newThumbnail, setNewThumbnail] = useState<File | null>(null);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: async () => getOrganization(VerdocsEndpoint.getDefault(), organizationId).then(getEditableFields),
  });

  // useEffect(() => {
  //   const editor = Jodit.make('#editor', {
  //     buttons: ['bold', 'italic', 'underline', '|', 'ul', 'ol'],
  //   });
  // }, []);

  const onSubmit = async (data: any) => {
    if (newLogo) {
      await updateOrganizationLogo(VerdocsEndpoint.getDefault(), organizationId, newLogo)
        .then(() => setNewLogo(null))
        .catch((e) => console.log('Unknown error', e));
    }

    if (newThumbnail) {
      await updateOrganizationThumbnail(VerdocsEndpoint.getDefault(), organizationId, newThumbnail)
        .then(() => setNewThumbnail(null))
        .catch((e) => console.log('Unknown error', e));
    }

    // await updateOrganization(VerdocsEndpoint.getDefault(), organizationId, {
    //   disclaimer: data.disclaimer,
    // })
    //   .then((r) => {
    //     form.reset(getEditableFields(r));
    //     invalidateQuery(['organization']);
    //   })
    //   .catch((e) => toast.error(`Error updating organization: ${e.message}`));
  };

  const dirty = form.formState.isDirty || newLogo || newThumbnail;

  return (
    <div className="px-4 py-6 w-full max-w-[640px]">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <FormColumns>
            <FormColumn className="flex-1">
              <JoditEditor
                ref={editor}
                value={form.formState.defaultValues?.disclaimer}
                config={{
                  readonly: false, // all options from https://xdsoft.net/jodit/docs/,
                  placeholder: '',
                }}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => {
                  // setContent(newContent);
                }} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
              {/*<textarea id="editor"></textarea>*/}

              {/*<TextInput label="Disclaimer" name="disclaimer" required />*/}
            </FormColumn>
          </FormColumns>

          <p>Custom disclaimers must be approved by Verdocs.</p>
          {/*<ul>*/}
          {/*  <li>EULA</li>*/}
          {/*  <li>Privacy Policy</li>*/}
          {/*</ul>*/}

          <VerdocsButton type="submit" label="Submit for Review" size="normal" disabled={form.formState.isSubmitting || !dirty} />
        </form>
      </FormProvider>
    </div>
  );
});
