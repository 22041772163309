import {z} from 'zod';
import {toast} from 'react-toastify';
import {SquarePen} from 'lucide-react';
import React, {FC, useState} from 'react';
import {zodResolver} from '@hookform/resolvers/zod';
import {FormProvider, useForm} from 'react-hook-form';
import {VerdocsButton, VerdocsSpinner} from '@verdocs/web-sdk-react';
import {ITemplate, updateTemplate, VerdocsEndpoint} from '@verdocs/js-sdk';
import {Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger} from '../Components';
import {FlexFill, FormLabel, SelectInput, Switch, TextInput} from '../Components';

const schema = z.object({
  name: z.string().trim().min(3, 'Name is required'),
  visibility: z.string(),
  sender: z.string(),
  initial_reminder: z.union([z.null(), z.coerce.number().min(1).max(30)]).nullish(),
  followup_reminders: z.union([z.null(), z.coerce.number().min(1).max(30)]).nullish(),
});

type SchemaType = z.infer<typeof schema>;

interface DialogProps {
  template: ITemplate;
  onSave: () => void;
}

const MS_PER_DAY = 24 * 60 * 60 * 1000;

export const EditTemplateDialog: FC<DialogProps> = ({template, onSave}) => {
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [sendReminders, setSendReminders] = useState(template.initial_reminder !== null);

  const form = useForm<SchemaType>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: {
      name: template?.name || '',
      visibility: template.visibility || 'private',
      sender: template.sender === 'template_owner' ? 'template_owner' : 'envelope_creator',
      initial_reminder: template.initial_reminder ? Math.floor(template.initial_reminder / MS_PER_DAY) : null,
      followup_reminders: template.followup_reminders ? Math.floor(template.followup_reminders / MS_PER_DAY) : null,
    },
  });

  const onSubmit = (data: any) => {
    setSubmitting(true);
    return updateTemplate(VerdocsEndpoint.getDefault(), template!.id, {
      ...data,
      initial_reminder: sendReminders ? data.initial_reminder * MS_PER_DAY : null,
      followup_reminders: sendReminders ? data.followup_reminders * MS_PER_DAY : null,
    })
      .then((r) => {
        setSubmitting(false);
        form.reset({
          name: r.name || '',
          visibility: r.visibility || 'private',
          sender: r.sender || 'envelope_creator',
          initial_reminder: r.initial_reminder ? Math.floor(r.initial_reminder / MS_PER_DAY) : null,
          followup_reminders: r.followup_reminders ? Math.floor(r.followup_reminders / MS_PER_DAY) : null,
        });
        onSave();
        setOpen(false);
      })
      .catch((e) => {
        setSubmitting(false);
        console.log('Error updating template', e);
        toast.error(`Error updating template. Please check your input and try again.`);
      });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <SquarePen className="size-6 opacity-70 hover:opacity-100" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogDescription />
          <DialogTitle className="mb-4">Edit Template</DialogTitle>

          <DialogContent>
            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <TextInput label="Name" placeholder="Template name..." {...form.register('name')} required />

                <SelectInput
                  name="visibility"
                  label="Visibility"
                  options={[
                    {value: 'private', label: 'Private'},
                    {value: 'shared', label: 'Shared'},
                    {value: 'public', label: 'Public'},
                  ]}
                />

                <SelectInput
                  name="sender"
                  label="Owner for envelopes created from this template"
                  options={[
                    {value: 'envelope_creator', label: 'Envelope Creator'},
                    {value: 'template_owner', label: 'Template Owner'},
                  ]}
                />

                <div className="flex flex-row gap-3 items-center mb-4 mt-8">
                  <FormLabel>Send Reminders</FormLabel>
                  <FlexFill />
                  <Switch
                    checked={sendReminders}
                    onCheckedChange={(checked) => {
                      setSendReminders(checked);
                      if (!checked) {
                        form.setValue('initial_reminder', null);
                        form.setValue('followup_reminders', null);
                      } else {
                        form.setValue('initial_reminder', 1);
                        form.setValue('followup_reminders', 3);
                      }
                    }}
                  />
                </div>

                {sendReminders && (
                  <>
                    <div className="flex flex-row gap-3 items-center">
                      <FormLabel>First Reminder (days)</FormLabel>
                      <FlexFill />
                      <TextInput placeholder="Delay in days..." {...form.register('initial_reminder')} />
                    </div>

                    <div className="flex flex-row gap-3 items-center">
                      <FormLabel>Follow-up Reminders (days)</FormLabel>
                      <FlexFill />
                      <TextInput placeholder="Delay in days..." {...form.register('followup_reminders')} />
                    </div>
                  </>
                )}

                <DialogFooter className="justify-end gap-3 flex-row mt-0 tablet:mt-8">
                  <VerdocsButton size="small" variant="outline" label="Cancel" onClick={() => setOpen(false)} />
                  <VerdocsButton size="small" label="Save" type="submit" disabled={!form.formState.isValid || submitting} />
                  {submitting && <VerdocsSpinner mode="dark" />}
                </DialogFooter>
              </form>
            </FormProvider>
          </DialogContent>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
